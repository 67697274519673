<template>
  <div class="pa-5">
    <v-row dense>
      <v-col cols="12">
        <v-icon class="mr-2">mdi-account</v-icon>
        <span class="titulo">Pesquisar usuários</span>
      </v-col>
    </v-row>

    <!-- FORMULÁRIO DE PESQUISA -->
    <v-form v-model="valid" ref="form" class="mt-20">
      <v-row dense>
        <v-col cols="6" md="9">
          <v-text-field
            solo dense autofocus
            ref="nome"
            label="Nome"
            v-model="data.Nome"
            prepend-inner-icon="mdi-face-man"
            @keypress.enter.prevent="pesquisar()" />
        </v-col>
        <v-col cols="6" md="3">
          <v-autocomplete
            solo dense
            label="Tipo"
            v-model="data.Tipo"
            :items="func.tiposUsuario"
            item-value="Id"
            item-text="Nome"
            prepend-inner-icon="mdi-police-badge"
            @change="pesquisar()" />
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <v-btn dark color="success" class="mx-1 mt-2" @click="pesquisar()">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            PESQUISAR
          </v-btn>
          <v-btn dark color="error" class="mx-1 mt-2" @click="limpaCampos()">
            <v-icon class="mr-2">mdi-close</v-icon>
            LIMPAR
          </v-btn>
          <v-btn dark color="blue darken-2" class="mx-1 mt-2" @click="novoUsuario()">
            <v-icon class="mr-2">mdi-plus-thick</v-icon>
            NOVO USUÁRIO
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <div class="mb-2 d-flex flex-column align-end">
            <b class="grey--text text--darken-2">
              <span class="blue--text text--darken-2">{{ itensPesq.length }}</span>
              usuário(s) encontrado(s)
            </b>
          </div>
          <v-data-table
            class="elevation-1 inscritos"
            :headers="headers"
            :items="itensPesq"
            :page.sync="pages"
            :items-per-page="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :loading="loading"
            :expanded.sync="expanded"
            item-key="Id"
            hide-default-footer
            @page-count="pageCount = $event">
            <template slot="no-data">
              <v-alert dark color="warning" icon="mdi-exclamation-thick" border="left" class="mt-3">
                REGISTROS NÃO ENCONTRADOS
              </v-alert>
            </template>
            <v-progress-linear slot="progress" color="red" indeterminate></v-progress-linear>
            <template v-slot:item="{ item }">
              <tr @click="expandRow(item)">
                <td align="left">{{ item.Nome }}</td>
                <td align="left">{{ item.Login }}</td>
                <td align="left">{{ item.Email }}</td>
                <td align="left">{{ func.tiposUsuario.filter(t => t.Id == item.Tipo)[0].Nome }}</td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length" class="text-center">
                <v-card flat style="border-bottom: solid 1px #ddd">
                  <v-card-text>
                    <v-btn rounded x-small dark class="mx-1" color="blue darken-2"
                      @click="editar(item)">
                      <v-icon small class="mr-2">mdi-pencil</v-icon>
                      Editar
                    </v-btn>
                    <v-btn rounded x-small dark class="mx-1" color="red" @click="excluir(item)">
                      <v-icon small class="mr-2">mdi-trash-can</v-icon>
                      Excluir
                    </v-btn>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <v-pagination class="mt-5" v-model="pages" :length="pageCount"></v-pagination>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dlgUsuario" persistent max-width="900px">
      <v-card>
        <v-card-text>
          <form-usuario
            :id-usuario="idUsuario"
            @create="pesquisar()"
            @update="pesquisar()">
          </form-usuario>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import func from "../../utils/funcoes";
import { mask } from "vue-the-mask";
import { eventHub } from "../../utils/eventHub";

import form_usuario from "../../components/usuarios/CadastroWidget.vue";

export default {
  components: {
    "form-usuario": form_usuario
  },
  directives: { mask },
  data () {
    return {
      func,
      valid: false,
      acao: "ins",
      data: {},

      cliques: 0,

      dlgUsuario: false,
      idUsuario: null,

      itensPesq: [],
      expanded: [],
      loading: false,
      pages: 1,
      pageCount: 0,
      itemsPerPage: 20,
      sortBy: "Nome",
      sortDesc: false,
      headers: [
        {
          text: "NOME",
          align: "left",
          sortable: true,
          value: "Nome",
        },
        {
          text: "LOGIN",
          align: "left",
          sortable: false,
          value: "Login",
        },
        {
          text: "EMAIL",
          align: "left",
          sortable: false,
          value: "Email",
        },
        {
          text: "TIPO",
          align: "left",
          sortable: false,
          value: "Tipo",
        }
      ],
    };
  },
  async mounted () {
    this.pesquisar();

    eventHub.$off("form-usuario");
    eventHub.$on("form-usuario", (data) => {
      if (data == false) this.idUsuario = null;
      this.dlgUsuario = data;
    });

    eventHub.$off("excluir-usuario");
    eventHub.$on("excluir-usuario", (data) => {
      if (data.flag) this.excluir(data.reg, data.flag);
    });
  },
  computed: {
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  methods: {
    expandRow (e) {
      if (!func.empty(this.expanded)) {
        let found = this.expanded.filter((item) => item.Id == e.Id);
        if (!func.empty(found)) this.expanded.splice(0, 1);
        else this.expanded.splice(0, 1, e);
      } else this.expanded.push(e);
    },
    pesquisar () {
      let data = func.jsonToQueryString(this.data);
      this.itensPesq = [];
      this.loading = true;

      let url = `api/v1/admin/usuarios/pesquisar${'?' + data}`
      window.axios.get(url)
        .then((res) => {
          if (res.data.error) {
            func.notifica("ERROR: " + res.data.message, "top", "red", 5000);
          } else {
            this.itensPesq = res.data.result;
          }
        })
        .catch((error) => {
          func.notifica(error, "top", "red", 5000);
        })
        .then(() => {
          this.loading = false;
        });
    },
    excluir (data, flag = false) {
      if (!flag) {
        let params = {};
        params.Open = true;
        params.Reg = data;
        params.Retorno = "excluir-usuario";
        params.Titulo = "EXCLUIR";
        params.Texto = `Deseja realmente excluir o usuário <strong>"${data.Nome}"</strong>?`;
        eventHub.$emit("dialog-excluir", params);
      } else {
        this.cliques++;
        if (this.cliques > 1) return;
        setTimeout(() => {
          this.cliques = 0;
        }, 1000);

        eventHub.$emit("dialog-aguarde", true);

        let url = `/api/v1/admin/usuarios/${data.Id}/delete`;
        window.axios.post(url)
          .then((res) => {
            if (res.data.error) {
              let msg = "";
              if (res.data.message == "negada")
                msg = "EXCLUSÃO NÃO PERMITIDA. Existem registros para este usuario!!!";
              else
                msg = res.data.message;

              func.notifica(`<strong>ERROR:</strong> ${msg}`, "top", "red", 5000);
            } else {
              func.notifica("Usuário excluído com sucesso!!!", "top", "green");
              this.pesquisar();
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            eventHub.$emit("dialog-aguarde", false);
          });
      }
    },
    editar (data) {
      this.idUsuario = data.Id;
      this.dlgUsuario = true;
    },
    limpaCampos () {
      this.itensPesq = [];
      this.$refs.form.reset();
      this.$refs.nome.focus();
      this.data = {};
    },
    novoUsuario () {
      this.idUsuario = null;
      this.dlgUsuario = true;
    }
  },
};
</script>
