<template>
  <div>
    <v-toolbar absolute fixed dense style="left: 0; width: 100%;" color="primary">
      <v-row dense align="center">
        <v-col cols="10" class="white--text">
          <v-icon color="white" class="mr-2">mdi-account</v-icon>
          <span class="text-h5">{{ nmAcao }} usuário</span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn icon color="white" @click="abreFechaJanela(false)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- FORMULÁRIO DE CADASTRO -->
    <v-form v-model="valid" ref="form" style="padding-top: 60px">
      <v-row dense>
        <v-col cols="12" md="7">
          <v-text-field
            outlined dense autofocus
            hide-details="auto"
            color="primary"
            ref="nome"
            label="Nome"
            v-model="data.Nome"
            :rules="validations.nome"
            prepend-inner-icon="mdi-face-man" />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            outlined dense
            hide-details="auto"
            color="primary"
            label="Email"
            v-model="data.Email"
            :rules="validations.email"
            prepend-inner-icon="mdi-at" />
        </v-col>

        <v-col cols="12" class="mt-1"></v-col>

        <v-col cols="12" md="4">
          <v-text-field
            outlined dense
            hide-details="auto"
            color="primary"
            label="Login"
            v-model="data.Login"
            @input="data.Login = func.removeAcento($event).toLocaleLowerCase()"
            :rules="validations.login"
            prepend-inner-icon="mdi-account" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined dense
            :type="showPass1 ? 'text' : 'password'"
            hide-details="auto"
            color="primary"
            label="Senha"
            v-model="data.Senha"
            :rules="validations.senha"
            prepend-inner-icon="mdi-form-textbox-password"
            :append-icon="showPass1 ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPass1 = !showPass1" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined dense
            :type="showPass2 ? 'text' : 'password'"
            hide-details="auto"
            color="primary"
            label="Confirmar Senha"
            v-model="cSenha"
            :rules="validations.csenha"
            prepend-inner-icon="mdi-form-textbox-password"
            :append-icon="showPass2 ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPass2 = !showPass2" />
        </v-col>

        <v-col cols="12" class="mt-1"></v-col>

        <v-col cols="12" md="9">
          <v-autocomplete
            outlined dense
            hide-details="auto"
            ref="empresa"
            label="Empresa"
            v-model="data.IdCliente"
            :items="tblClientes"
            item-value="Id"
            item-text="Nome"
            :search-input.sync="searchClientes"
            :loading="clienteLoad"
            hide-no-data
            hide-selected
            :rules="validations.cli"
            prepend-inner-icon="mdi-office-building" />
        </v-col>
        <v-col cols="6" md="3">
          <v-autocomplete
            outlined dense
            label="Tipo"
            v-model="data.Tipo"
            :items="tblTipos"
            item-value="Id"
            item-text="Nome"
            prepend-inner-icon="mdi-police-badge"
            @change="pesquisar()" />
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <v-btn color="success" class="mx-1 mt-2" :disabled="!valid" @click="submit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            SALVAR
          </v-btn>
          <v-btn dark color="error" class="mx-1 mt-2" @click="limpaCampos()">
            <v-icon class="mr-2">mdi-close</v-icon>
            LIMPAR
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="carregando">
      <v-card color="primary" dark width="150">
        <v-card-text class="text-center">
          Aguarde...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>
 
<script>
import func from "../../utils/funcoes";
import { eventHub } from "../../utils/eventHub";

export default {
  props: {
    idUsuario: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      func,
      valid: false,
      carregando: false,
      data: {},
      acao: "ins",

      cliques: 0,

      showPass1: false,
      showPass2: false,
      cSenha: null,

      tblTipos: JSON.parse(JSON.stringify(func.tiposUsuario)),
      tblClientes: [],
      clienteLoad: false,
      searchClientes: null,
      searchTimmer: null,

      validations: {
        nome: [
          (v) => !!v || "Nome é obrigatório",
          (v) => (v && func.vldNome(v)) || "Faltando sobrenome ou nome inválido"
        ],
        email: [
          (v) => !!v || "E-mail é obrigatório",
          (v) => (v && func.vldMail(v)) || "E-mail inválido"
        ],
        login: [
          (v) => !!v || "Login é obrigatório",
          (v) => (v && v.indexOf(" ") == -1) || "Login inválido"
        ],
        senha: [(v) => func.empty(v) || v.length > 5 || "Senha é inválida"],
        csenha: [(v) => func.empty(v) || v == this.data.Senha || "Senhas diferentes"],
        cli: [(v) => !!v || "Empresa é obrigatório"],
      },
    };
  },
  created () {
    this.tblTipos.shift();
  },
  async mounted () {
    if (this.idUsuario) this.pegaDados(this.idUsuario);
  },
  computed: {
    nmAcao () {
      return this.acao == "ins" ? "Novo" : "Alterar";
    },
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  watch: {
    async searchClientes (texto) {
      if (func.empty(texto)) { this.tblClientes = []; return; }
      if (texto.length < 3 || this.clienteLoad) return;

      clearTimeout(this.searchTimmer);
      this.searchTimmer = setTimeout(async () => {
        this.tblClientes = await this.pegaClientes(texto);
      }, 500);
    },
    idUsuario (vlr) {
      let status = true;

      if (func.empty(vlr)) {
        this.limpaCampos();
        status = false;
      } else {
        this.pegaDados(vlr);
      }

      this.abreFechaJanela(status);
    }
  },
  methods: {
    abreFechaJanela (data) {
      if (data == false) this.limpaCampos();
      eventHub.$emit("form-usuario", data);
    },
    async pegaClientes (texto, id = false) {
      let url = `/api/v1/admin/clientes/pesquisar?Nome=${texto}`;
      if (id) url = `/api/v1/admin/clientes?id=${id}`;

      this.clienteLoad = true;

      return window.axios.get(url)
        .then((res) => {
          if (!func.empty(res.data.result.error)) {
            func.notifica(res.data.result.message, "top", "red", 5000);
            return [];
          } else {
            if (id) return [res.data.result];
            else return res.data.result;
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return [];
        }).finally(() => {
          this.clienteLoad = false;
        });
    },
    async pegaDados (id) {
      if (func.empty(id)) return;

      this.carregando = true;

      let url = `/api/v1/admin/usuarios?id=${id}`;
      return window.axios.get(url)
        .then((res) => {
          if (res.data.result.error)
            func.notifica(res.data.result.message, "top", "red", 5000);
          else
            this.compoeCampos(res.data.result);

          return true;
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return false;
        })
        .finally(() => {
          this.carregando = false;
        });
    },
    async compoeCampos (dados) {
      this.acao = "upd";
      if (dados.IdCliente) this.tblClientes = await this.pegaClientes('', dados.IdCliente);
      this.data = JSON.parse(JSON.stringify(dados));

      delete this.data.Status;
      delete this.data.Senha;
      delete this.data.DtUltLogin;
    },
    submit () {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      let dados = JSON.parse(JSON.stringify(this.data));

      this.carregando = true;

      let url = `/api/v1/admin/usuarios`;
      if (this.acao == "ins") {
        if (dados.Senha != this.cSenha) {
          func.notifica("Senhas diferentes", "top", "red", 5000);
          return;
        }

        window.axios.post(url, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Usuário inserido com sucesso!!!", "top", "green");
              this.$emit('create', res.data);
              this.limpaCampos();
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      } else if (this.acao == "upd") {
        if (func.empty(dados.Senha)) delete dados.Senha;
        else if (dados.Senha != this.cSenha) {
          func.notifica("Senhas diferentes", "top", "red", 5000);
          return;
        }

        window.axios.post(`${url}/${dados.Id}`, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Usuário alterado com sucesso!!!", "top", "green");
              this.$emit('update', res.data);
              this.abreFechaJanela(false);
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      }
    },
    limpaCampos () {
      this.acao = "ins";
      this.$refs.form.reset();
      this.$refs.nome.focus();
      this.data = {};
    },
  },
};
</script>
